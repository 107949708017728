import React, { useState } from 'react';

import { styled, theme } from '../Theme';
import { UpdateCart } from '../Cart/Cart';
import { media, pseudo } from '@glitz/core';
import { LoadingCircle } from '../Shared/Icons';
import CtaButton from '../Shared/CtaButton/CtaButton';
import PlusToggleIcon from '../Shared/Icons/PlusToggleIcon';
import MinusToggleIcon from '../Shared/Icons/MinusToggleIcon';
import { mediaQuery } from '../Theme/mediaQueries';
import VariationModel from '../KexVariation/Models/VariationModel.interface';
import CheckIcon from '../Shared/Icons/CheckIcon';
import PlanogramPageModel from './Models/PlanogramPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import useMedia from '../Shared/Hooks/useMedia';

type AttributeArea = {
  variation: VariationModel;
  bookingNumber: string;
  planogramInputQuantity: number;
  planogramVariantList?: VariationModel[];
  shortDescription?: string;
};

function AttributeArea({
  variation: { totalPrice, code },
  bookingNumber,
  planogramInputQuantity,
  planogramVariantList,
  shortDescription,
}: AttributeArea) {
  const [inputQuantity, setInputQuantity] = useState<number>(
    planogramInputQuantity
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemAdded, setItemAdded] = useState(false);

  const {
    translations: {
      'productPage/addToCart': addToCartLabel,
      'productPage/inStock': inStockLabel,
      'productPage/notInStock': notInStockLabel,
      'productPage/addedToCart': addedToCartLabel,
      'productPage/eanCode': eanCodeLabel,
      'productPage/quantity': quantityLabel,
      'productPage/priceGroupName': priceGroupNameLabel,
      'productPage/va': vaLabel,
      'productPage/position': positionLabel,
    },
    languageRoute,
  } = useAppSettingsData();

  const { variationCollection } = useCurrentPage<PlanogramPageModel>();
  const planogram = variationCollection && variationCollection[0];
  const inStock = planogram.inStock;
  const isMobile = useMedia(theme.mediaQuery.mediaMaxMedium);

  const onInputChange = (value: string) => {
    if (Number(value) < 0) {
      setInputQuantity(1);
      return;
    }
    setInputQuantity(Number(value));
  };

  const onInputBlur = (numb: string) => {
    Number(numb) < 1 && setInputQuantity(1);
  };

  const addToCart = () => {
    const updated = UpdateCart(
      code,
      inputQuantity,
      languageRoute,
      bookingNumber ? bookingNumber : '',
      setIsLoading
    );
    if (updated) {
      setItemAdded(true);
      setTimeout(() => {
        setItemAdded(false);
      }, 2000);
    }
  };

  return (
    <AttrContainerWrapper>
      <Heading>{planogram.name}</Heading>
      <PlanogramArticleNumber>{planogram.articleNumber}</PlanogramArticleNumber>
      <Description>{shortDescription}</Description>
      <StockStatus>
        {inStock ? (
          <>
            <StockStatusCircle data-instock={inStock} />
            <StockStatusText>{inStockLabel}</StockStatusText>
          </>
        ) : (
          <>
            <StockStatusCircle />
            <StockStatusText>{notInStockLabel}</StockStatusText>
          </>
        )}
      </StockStatus>

      {!isMobile ? (
        <Table>
          <TableRow>
            <TableCell>{priceGroupNameLabel}</TableCell>
            <TableCell>{quantityLabel}</TableCell>
            <TableCell>{eanCodeLabel}</TableCell>
            <TableCell>{vaLabel}</TableCell>
          </TableRow>
          {planogramVariantList?.map(productItem => (
            <TableRow key={productItem.articleNumber}>
              <>
                <TableCell>{productItem.priceGroupName}</TableCell>
                <TableCell>{productItem.quantity}</TableCell>
                <TableCell>{productItem.eanCode}</TableCell>
                <TableCell>{productItem.recommendedPrice}</TableCell>
              </>
            </TableRow>
          ))}
        </Table>
      ) : (
        planogramVariantList?.map(productItem => (
          <Content>
            <Array data-whitebackground="true">
              <ArrayOrderItemHeading>
                {priceGroupNameLabel}
              </ArrayOrderItemHeading>
              <ArrayOrderItem>{productItem.priceGroupName}</ArrayOrderItem>
            </Array>
            <Array>
              <ArrayOrderItemHeading>{quantityLabel}</ArrayOrderItemHeading>
              <ArrayOrderItem>{productItem.quantity}</ArrayOrderItem>
            </Array>
            <Array data-whitebackground="true">
              <ArrayOrderItemHeading>{eanCodeLabel}</ArrayOrderItemHeading>
              <ArrayOrderItem>{productItem.articleNumber}</ArrayOrderItem>
            </Array>
            <Array>
              <ArrayOrderItemHeading>{vaLabel}</ArrayOrderItemHeading>
              <ArrayOrderItem>{productItem.recommendedPrice}</ArrayOrderItem>
            </Array>
          </Content>
        ))
      )}
      {inStock && (
        <>
          <PriceContainer>
            <MainPriceContainer>
              <Price>{totalPrice}</Price>
            </MainPriceContainer>
          </PriceContainer>
          <div>
            <AddToCart>
              <QuantitySelector>
                <MinusButton
                  disabled={inputQuantity === 1}
                  onClick={() => setInputQuantity(inputQuantity - 1)}
                >
                  <StyledMinusIcon />
                </MinusButton>
                <QuantityInput
                  type={'number'}
                  min={'1'}
                  max={'99'}
                  value={inputQuantity}
                  onChange={e => onInputChange(e.target.value)}
                  onBlur={e => onInputBlur(e.target.value)}
                />
                <button onClick={() => setInputQuantity(inputQuantity + 1)}>
                  <StyledPlusIcon />
                </button>
              </QuantitySelector>
              <AddToCartButton onClick={() => addToCart()}>
                <ButtonText data-isloading={isLoading || itemAdded}>
                  {addToCartLabel}
                </ButtonText>
                {isLoading && <StyledLoadingCircle isLoading={isLoading} />}
                {itemAdded && !isLoading && (
                  <ItemAddedText>
                    {addedToCartLabel}
                    <StyledCheckIcon />
                  </ItemAddedText>
                )}
              </AddToCartButton>
            </AddToCart>
          </div>
        </>
      )}
    </AttrContainerWrapper>
  );
}

const CellRow = styled.span({
  color: theme.breadText,
  font: { size: theme.alpha, weight: theme.fontWeight.bold },
  textTransform: 'uppercase',
});

const Cell = styled.div({});

const AttrContainerWrapper = styled.div({
  width: '100%',
  ...media(theme.mediaQuery.mediaMinLarge, {
    maxWidth: '630px',
  }),
});

const StyledLoadingCircle = styled(LoadingCircle, {
  color: theme.white,
  height: theme.gamma,
  margin: { xy: 'auto' },
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const ButtonText = styled.span({
  transition: {
    property: 'all',
    duration: '0.2s',
    timingFunction: 'linear',
  },
  ...pseudo([':nth-child(n)[data-isloading="true"]'], {
    visibility: 'hidden',
    opacity: 0,
  }),
});

const Heading = styled.h1({
  padding: {
    top: theme.spacing(5),
  },
  font: {
    size: theme.psi,
    weight: theme.fontWeight.bold,
  },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.wider,
  color: theme.black,
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: {
      size: '32px',
    },
    padding: {
      top: 'none',
    },
  }),
});

const Table = styled.div({
  display: 'table',
  width: '100%',
  borderCollapse: 'collapse',
});

const Content = styled.div({
  borderTopColor: theme.grayLine,
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  margin: { y: theme.spacing(5) },
  paddingTop: theme.spacing(6),
});

const Array = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  lineHeight: '22.4px',
  padding: { x: theme.spacing(4), y: '10px' },
  ...pseudo([':nth-child(n)[data-whitebackground="true"]'], {
    backgroundColor: theme.white,
  }),
});

const ArrayOrderItemHeading = styled.span({
  fontSize: theme.gamma,
  color: theme.breadText,
});

const ArrayOrderItem = styled.span({
  fontSize: theme.gamma,
  color: theme.black,
  lineHeight: '25.6px',
});

const ArrayOrderNumber = styled.span({
  fontSize: theme.gamma,
  color: theme.linkColor,
  cursor: 'pointer',
  textDecoration: 'underline',
});

const TableRow = styled.div({
  display: 'table-row',
  borderBottomColor: theme.grayLine,
  borderBottomStyle: 'solid',
  borderBottomWidth: '2px',
  padding: { y: theme.spacing(3) },
});

const TableCell = styled.div({
  display: 'table-cell',
  textAlign: 'left',
  fontSize: theme.large,
  padding: { y: theme.spacing(3), x: theme.spacing(2) },
  wordBreak: 'break-all',
  ...media(mediaQuery.mediaMinVerySmall, {
    fontSize: theme.beta,
    padding: { y: theme.spacing(3), x: theme.spacing(4) },
  }),
});

const StockStatus = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: { bottom: theme.spacing(8), top: theme.spacing(4) },
  ...media(mediaQuery.mediaMinLarge, {
    marginBottom: theme.spacing(6),
  }),
});

const StyledCheckIcon = styled(CheckIcon, {
  height: '18px',
  width: '18px',
  marginLeft: '8px',
});

const ItemAddedText = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.white,
  height: theme.gamma,
  margin: { xy: 'auto' },
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const StockStatusCircle = styled.div({
  borderRadius: '50%',
  width: '8px',
  height: '8px',
  backgroundColor: theme.notInStockRed,
  marginRight: theme.spacing(2),
  ...pseudo([':nth-child(n)[data-instock="true"]'], {
    backgroundColor: theme.inStockGreen,
  }),
});

const StockStatusText = styled.span({
  color: theme.black,
  font: { size: theme.alpha, weight: theme.fontWeight.bold },
  textTransform: 'uppercase',
});

const PriceContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: {
    top: theme.theta,
    bottom: theme.psi,
  },
});

const MainPriceContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const Price = styled.span({
  font: {
    size: theme.nearGreat,
    weight: theme.fontWeight.bold,
  },
  marginTop: theme.spacing(2),
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.wider,
  color: theme.black,
});

const AddToCart = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const QuantitySelector = styled.div({
  backgroundColor: theme.white,
  borderRadius: '100px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '35%',
  alignItems: 'center',
  padding: { x: theme.spacing(5), y: '14px' },
  ...media(mediaQuery.mediaMinLarge, {
    padding: { y: '18px' },
  }),
});

const MinusButton = styled.button({
  ':disabled': {
    cursor: 'default',
    opacity: 0.6,
  },
});

const QuantityInput = styled.input({
  width: theme.spacing(7),
  backgroundColor: theme.white,
  textAlign: 'center',
  MozAppearance: 'textfield', // hide number arrows Firefox
  ...pseudo(['::-webkit-outer-spin-button', '::-webkit-inner-spin-button'], {
    WebkitAppearance: 'none', // hide number arrows Chrome, Safari, Edge, Opera
    margin: { xy: 0 },
  }),
});

const StyledPlusIcon = styled(PlusToggleIcon, {
  height: '20px',
  width: '20px',
  fill: theme.black,
});

const StyledMinusIcon = styled(MinusToggleIcon, {
  height: '20px',
  width: '20px',
  fill: theme.black,
});

const AddToCartButton = styled(CtaButton, {
  position: 'relative',
  width: '65%',
  fontSize: theme.moreLarge,
  textAlign: 'center',
  lineHeight: '1.3em',
  padding: { x: theme.spacing(5), y: '15px' },
  marginLeft: theme.spacing(5),
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { y: theme.spacing(5) },
  }),
});

const AddToCartError = styled.span({
  color: theme.red,
  textAlign: 'center',
  fontSize: theme.beta,
  lineHeight: theme.lineHeight.loose,
  letterSpacing: theme.letterSpacing.wide,
});

const cartIconCss = {
  width: theme.gamma,
  height: theme.epsilon,
  margin: { right: theme.medium },
};

const AttributeProperty = styled.p({
  height: 20,
  margin: {
    bottom: theme.large,
  },
  font: {
    size: theme.gamma,
    weight: theme.fontWeight.normal,
  },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.wide,
  color: theme.breadText,
});

const AttributeValue = styled.span({
  height: 20,
  font: {
    size: theme.gamma,
    weight: theme.fontWeight.lighter,
  },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.wide,
  color: theme.breadText,
});

const ProductAttrListWrapper = styled.div({
  whiteSpace: 'pre-line',
  textAlign: 'center',
});

const PlanogramArticleNumber = styled.span({
  font: { size: theme.beta },
  textAlign: 'center',
  color: theme.breadText,
  marginTop: theme.spacing(3),
});

const Description = styled.p({
  color: theme.breadText,
  margin: { y: theme.large },
  lineHeight: theme.lineHeight.relaxed,
  letterSpacing: theme.letterSpacing.wide,
  font: {
    size: theme.moreLarge,
    weight: theme.fontWeight.normal,
  },
});

export default AttributeArea;
