import React from 'react';
import { styled } from '@glitz/react';
import PlanogramPageModel from './Models/PlanogramPageModel.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { theme } from '../Theme';
import { media, pseudo } from '@glitz/core';
import PlanogramRowGrid from './PlanogramRowGrid';
import useMedia from '../Shared/Hooks/useMedia';
import { mediaQuery } from '../Theme/mediaQueries';
import MyPlanogramsComponent from '../../Features/MyPlanogramsPage/MyPlanogramsComponent';
import ArrowLeftIconPrimary from '../Shared/Icons/ArrowLeftIconPrimary';
import KexLink from '../Shared/KexLink/KexLink';
import PlanogramProductPage from './PlanogramProductPage';

function PlanogramPage() {
  const {
    pageTitle,
    planogramProductList,
    variationCollection,
    isProductPage,
    isSeasonal,
  } = useCurrentPage<PlanogramPageModel>();

  const {
    translations: {
      'planogramPage/clickAndOrderTitle': infoTitle,
      'planogramPage/clickAndOrderInfo': infoText,
      'planogramPage/backToAllPlanograms': backToAllLinkLabel,
      'planogramPage/backToAllSeasonalPlanograms': backToAllSeasonalLinkLabel,
    },
    staticPages: { myPlanogramsPage, myPlanogramsSeasonalPage },
  } = useAppSettingsData();

  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);
  const planogramId = variationCollection[0].articleNumber;

  const hasMoreThanThreeColumns = () => {
    let useScroll = false;
    planogramProductList.forEach((element, index) => {
      if (element.length <= 3) return false;
      element.map(product => {
        if (product.columnPosition > 3) {
          useScroll = true;
          return useScroll;
        }
      });
    });
    return useScroll;
  };

  const useScroll = hasMoreThanThreeColumns() && !isDesktop;

  return (
    <main id="main">
      {!isProductPage ? (
        <>
          <BackLinkWrapper>
            <ArrowLeftIcon />
            <BackLink
              href={isSeasonal ? myPlanogramsSeasonalPage : myPlanogramsPage}
            >
              {isSeasonal ? backToAllSeasonalLinkLabel : backToAllLinkLabel}
            </BackLink>
          </BackLinkWrapper>
          <PageWrapper>
            <TopContent>
              <Heading>{pageTitle}</Heading>
              <InfoTitle>{infoTitle}</InfoTitle>
              <InfoText>{infoText}</InfoText>
            </TopContent>
            <GridContainer data-usescroll={useScroll}>
              <Wrapper data-usescroll={useScroll}>
                {planogramProductList.map((productListRow, index) => (
                  <PlanogramRowGrid
                    key={index}
                    row={productListRow}
                    isLast={index === planogramProductList.length - 1}
                    useScroll={useScroll}
                    planogramId={planogramId}
                  />
                ))}
              </Wrapper>
            </GridContainer>
            <MyPlanogramsComponent />
          </PageWrapper>
        </>
      ) : (
        <PlanogramProductPage />
      )}
    </main>
  );
}

const BackLinkWrapper = styled.div({
  position: 'absolute',
  top: theme.desktopHeaderHeight,
  left: 0,
  right: 0,
  margin: { x: 'auto' },
  maxWidth: theme.screenMaxWidth,
  width: '100%',
  paddingTop: '18px',
  alignItems: 'center',
  display: 'flex',
  paddingLeft: '32px',
  lineHeight: 1.33,
  letterSpacing: 0.45,
  ...media(theme.mediaQuery.mediaMaxLarge, {
    paddingTop: '0px',
  }),
  ...media(theme.mediaQuery.mediaMaxMedium, {
    paddingLeft: '20px',
  }),
});

const ArrowLeftIcon = styled(ArrowLeftIconPrimary, {
  fill: theme.linkColor,
  marginRight: theme.spacing(4),
});

const BackLink = styled(KexLink, {
  fontSize: theme.gamma,
  color: theme.linkColor,
  cursor: 'pointer',
  textDecoration: 'underline',
});

const PageWrapper = styled.div({
  backgroundColor: theme.primaryBackground,
  paddingTop: theme.spacing(14),
  paddingBottom: theme.spacing(19),
  ...media(theme.mediaQuery.mediaMinLarge, {
    paddingTop: theme.spacing(18),
    paddingBottom: theme.spacing(20),
  }),
});

const TopContent = styled.div({
  maxWidth: theme.maxWidthContentPage,
  padding: { x: '7.5px' },
  margin: { x: 'auto' },
  marginBottom: theme.spacing(13),
  ...media(theme.mediaQuery.mediaMinLarge, {
    marginBottom: theme.spacing(11),
  }),
});

const InfoTitle = styled.h2({
  fontSize: '22px',
  lineHeight: '26.4px',
  color: theme.black,
  marginBottom: theme.spacing(2),
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.zeta,
    lineHeight: '33.6px',
    marginBottom: theme.spacing(1),
  }),
});

const InfoText = styled.p({ fontSize: theme.gamma, lineHeight: '25.6px' });

const Heading = styled.h1({
  maxWidth: theme.maxWidthSmall,
  font: { size: theme.psi, weight: theme.fontWeight.bold },
  color: theme.black,
  lineHeight: '31.2px',
  marginBottom: theme.spacing(5),
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: { size: '32px' },
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
    lineHeight: '40px',
  }),
});

const GridContainer = styled.div({
  borderSpacing: '10px',
  margin: { x: '7.5px' },
  marginBottom: '65px',
  maxWidth: '100%',
  ...media(mediaQuery.mediaMinMedium, {
    margin: { x: 'auto' },
  }),
  ...media(mediaQuery.mediaMaxMedium, {
    ...pseudo([':nth-child(n)[data-usescroll="true"]'], {
      overflowX: 'scroll',
      paddingBottom: theme.spacing(5),
      ...pseudo('::-webkit-scrollbar', {
        height: '12px',
        borderRadius: '100px',
        backgroundColor: theme.white,
        border: {
          xy: {
            style: 'solid',
            width: theme.tiny,
            color: theme.veryLightGray,
          },
        },
      }),
      ...pseudo('::-webkit-scrollbar-thumb', {
        width: '60px',
        borderRadius: '100px',
        backgroundColor: theme.primaryBlue,
      }),
    }),
  }),
  ...media(mediaQuery.mediaMinLarge, {
    maxWidth: '900px',
    margin: { x: 'auto' },
    overflowX: 'unset',
  }),
});

const Wrapper = styled.div({
  padding: { xy: '10px' },
  margin: { x: 'auto' },
  backgroundColor: theme.white,
  ...pseudo([':nth-child(n)[data-usescroll="true"]'], {
    width: 'fit-content',
  }),
  ...media(mediaQuery.mediaMinMedium, {
    width: 'fit-content',
  }),
  ...media(mediaQuery.mediaMinLarge, {
    overflowX: 'unset',
    width: '100%',
  }),
});

export default PlanogramPage;
