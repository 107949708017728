import React, { useState, useRef, useEffect } from 'react';
import ProductInGrid from './Models/ProductInGrid.interface';
import { styled } from '@glitz/react';
import { theme } from '../Theme';
import { pseudo, media } from '@glitz/core';
import { mediaQuery } from '../Theme/mediaQueries';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { UpdateCart } from '../Cart/Cart';
import useMedia from '../Shared/Hooks/useMedia';
import CtaButton from '../Shared/CtaButton/CtaButton';
import PlusToggleIcon from '../Shared/Icons/PlusToggleIcon';
import MinusToggleIcon from '../Shared/Icons/MinusToggleIcon';
import CheckIcon from '../Shared/Icons/CheckIcon';
import { LoadingCircle } from '../Shared/Icons';
import useOutsideClick from '../Shared/Hooks/useOutsideClick';
import CloseIcon from '../Shared/Icons/CloseIcon';

import { createPopper } from '@popperjs/core';

type PropType = {
  product: ProductInGrid;
  planogramId: string;
};

function PlanogramProductCard({ product, planogramId }: PropType) {
  const {
    translations: {
      'productPage/addToCart': addToCartLabel,
      'common/moreInformation': moreInformationLabel,
      'common/quantity': quantityLabel,
      'common/perPackage': perPackageLabel,
      'common/piece': pieceLabel,
      'productPage/inStock': inStockLabel,
      'productPage/notInStock': notInStockLabel,
      'productPage/addedToCart': addedToCartLabel,
      'productPage/position': positionLabel,
    },
    languageRoute,
    placeholderImages: { productPlaceholder: productPlaceholderImg },
  } = useAppSettingsData();

  const [isActive, setIsActive] = useState(false);
  const [inputQuantity, setInputQuantity] = useState<string>('1');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemAdded, setItemAdded] = useState(false);

  const cardRef = useRef<null | HTMLDivElement>(null)!;
  const popupRef = useRef<null | HTMLDivElement>(null);

  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);
  const inStock = product.product && product.product.inStock;

  useEffect(() => {
    const productImage =
      document &&
      document.querySelector(`#productImage-${product.bookingNumber}`)!;

    const tooltip =
      document &&
      document.querySelector<HTMLElement>(`#tooltip-${product.bookingNumber}`)!;

    createPopper(productImage, tooltip, {
      placement: 'bottom',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 15],
          },
        },
      ],
    });
  }, [isActive]);

  const onCardClick = (val: boolean) => {
    !isActive && setIsActive(val);
    if (!isActive) {
      cardRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  };

  useOutsideClick(cardRef, () => {
    if (isActive) {
      setIsActive(false);
    }
  });

  const onInputChange = (value: string) => {
    if (Number(value) < 0) {
      setInputQuantity('1');
      return;
    }
    setInputQuantity(value);
  };

  const onInputBlur = (numb: string) => {
    Number(numb) < 1 && setInputQuantity('1');
  };

  const addToCart = () => {
    const updated = UpdateCart(
      product.product.code,
      Number(inputQuantity),
      languageRoute,
      product.bookingNumber,
      setIsLoading
    );
    if (updated) {
      setItemAdded(true);
      setTimeout(() => {
        setItemAdded(false);
      }, 2000);
    }
  };

  const quantityQuery =
    Number(inputQuantity) > 1 ? `&quantity=${inputQuantity}` : '';

  const onCrossClick = () => {
    setIsActive(false);
  };

  return (
    <ProductCardWrapper>
      <ProductCard
        onClick={() => onCardClick(true)}
        ref={cardRef}
        data-is-active={isActive}
      >
        {product.product.image ? (
          <ProductImage
            id={'productImage-' + product.bookingNumber}
            src={product.product.image && product.product.image.src}
          />
        ) : (
          <ProductImage
            id={'productImage-' + product.bookingNumber}
            src={productPlaceholderImg?.src}
          ></ProductImage>
        )}
        <CellRow>
          {product.rowPosition}/{product.columnPosition}
        </CellRow>
        <PopperWrapper
          data-ishovered={isActive}
          id={'tooltip-' + product.bookingNumber}
          role="tooltip"
        >
          <ProductCardModalContainer ref={popupRef}>
            {product.product.image ? (
              <ModalProductImage
                src={product.product.image && product.product.image.src}
              />
            ) : (
              <ModalProductImage
                src={productPlaceholderImg?.src}
              ></ModalProductImage>
            )}
            <AttributeArea>
              <CloseIconWrapper onClick={onCrossClick}>
                <StyledCloseIcon />
              </CloseIconWrapper>
              <TextWrapper>
                <ProductTitle>
                  {product.product && product.product.name}
                </ProductTitle>
                <StockStatus>
                  {inStock ? (
                    <>
                      <StockStatusCircle data-instock={inStock} />
                      <StockStatusText>{inStockLabel}</StockStatusText>
                    </>
                  ) : (
                    <>
                      <StockStatusCircle />
                      <StockStatusText>{notInStockLabel}</StockStatusText>
                    </>
                  )}
                </StockStatus>
                <Cell>
                  <CellPosition>
                    {positionLabel}:&nbsp;
                    {product.rowPosition}/{product.columnPosition}
                  </CellPosition>
                </Cell>
                <QuantityText>
                  {quantityLabel}/{perPackageLabel}:&nbsp;
                  {product.product.quantity}
                  {pieceLabel}
                </QuantityText>
              </TextWrapper>
              <div>
                {inStock && (
                  <AddToCart>
                    <QuantitySelector>
                      <MinusButton
                        disabled={inputQuantity === '1'}
                        onClick={() =>
                          setInputQuantity(
                            (Number(inputQuantity) - 1).toString()
                          )
                        }
                      >
                        <StyledMinusIcon />
                      </MinusButton>
                      <QuantityInput
                        type={'number'}
                        min={'1'}
                        max={'99'}
                        value={inputQuantity}
                        onChange={e => onInputChange(e.target.value)}
                        onBlur={e => onInputBlur(e.target.value)}
                      />
                      <button
                        onClick={() =>
                          setInputQuantity(
                            (Number(inputQuantity) + 1).toString()
                          )
                        }
                      >
                        <StyledPlusIcon />
                      </button>
                    </QuantitySelector>
                    <AddToCartButton onClick={() => addToCart()}>
                      <ButtonText data-isloading={isLoading || itemAdded}>
                        {addToCartLabel}
                      </ButtonText>
                      {isLoading && (
                        <StyledLoadingCircle isLoading={isLoading} />
                      )}
                      {itemAdded && !isLoading && (
                        <ItemAddedText>
                          {addedToCartLabel}
                          <StyledCheckIcon />
                        </ItemAddedText>
                      )}
                    </AddToCartButton>
                  </AddToCart>
                )}
                <div>
                  <MoreInfoButton
                    href={`${product.product.url}?planogramId=${planogramId}&bookingNr=${product.bookingNumber}${quantityQuery}`}
                    asLink
                  >
                    {moreInformationLabel}
                  </MoreInfoButton>
                </div>
              </div>
            </AttributeArea>
          </ProductCardModalContainer>
          <PopperArrow id="arrow" data-popper-arrow />
        </PopperWrapper>

        <PopperWrapper
          onClick={() => onCardClick(true)}
          id={'tooltip-' + product.bookingNumber}
          role="tooltip"
        >
          <ProductCardModalContainer ref={popupRef}>
            <ModalProductImage
              src={product.product.image && product.product.image.src}
            />
            <AttributeArea>
              <CloseIconWrapper onClick={onCrossClick}>
                <StyledCloseIcon />
              </CloseIconWrapper>
              <TextWrapper>
                <ProductTitle>
                  {product.product && product.product.name}
                </ProductTitle>
                <StockStatus>
                  {inStock ? (
                    <>
                      <StockStatusCircle data-instock={inStock} />
                      <StockStatusText>{inStockLabel}</StockStatusText>
                    </>
                  ) : (
                    <>
                      <StockStatusCircle />
                      <StockStatusText>{notInStockLabel}</StockStatusText>
                    </>
                  )}
                </StockStatus>
                <QuantityText>
                  {quantityLabel}/{perPackageLabel}:&nbsp;
                  {product.product.quantity}
                  {pieceLabel}
                </QuantityText>
              </TextWrapper>

              <div>
                {inStock && (
                  <AddToCart>
                    <QuantitySelector>
                      <MinusButton
                        disabled={inputQuantity === '1'}
                        onClick={() =>
                          setInputQuantity(
                            (Number(inputQuantity) - 1).toString()
                          )
                        }
                      >
                        <StyledMinusIcon />
                      </MinusButton>
                      <QuantityInput
                        type={'number'}
                        min={'1'}
                        max={'99'}
                        value={inputQuantity}
                        onChange={e => onInputChange(e.target.value)}
                        onBlur={e => onInputBlur(e.target.value)}
                      />
                      <button
                        onClick={() =>
                          setInputQuantity(
                            (Number(inputQuantity) + 1).toString()
                          )
                        }
                      >
                        <StyledPlusIcon />
                      </button>
                    </QuantitySelector>
                    <AddToCartButton onClick={() => addToCart()}>
                      <ButtonText data-isloading={isLoading || itemAdded}>
                        {addToCartLabel}
                      </ButtonText>
                      {isLoading && (
                        <StyledLoadingCircle isLoading={isLoading} />
                      )}
                      {itemAdded && !isLoading && (
                        <ItemAddedText>
                          {addedToCartLabel}
                          <StyledCheckIcon />
                        </ItemAddedText>
                      )}
                    </AddToCartButton>
                  </AddToCart>
                )}
                <div>
                  <MoreInfoButton
                    href={`${product.product.url}?planogramId=${planogramId}&bookingNr=${product.bookingNumber}${quantityQuery}`}
                    asLink
                  >
                    {moreInformationLabel}
                  </MoreInfoButton>
                </div>
              </div>
            </AttributeArea>
          </ProductCardModalContainer>
          <PopperArrow id="arrow" data-popper-arrow />
        </PopperWrapper>
      </ProductCard>
    </ProductCardWrapper>
  );
}

const CellPosition = styled.div({
  color: theme.breadText,
  font: { size: theme.alpha, weight: theme.fontWeight.bold },
  textTransform: 'uppercase',
});

const CellRow = styled.span({
  position: 'absolute',
  left: '10px',
  color: theme.breadText,
  font: { size: theme.alpha, weight: theme.fontWeight.bold },
  textTransform: 'uppercase',
});

const Cell = styled.div({});

const PopperArrow = styled.div({
  ...pseudo(':before', {
    content: '""',
    position: 'absolute',
    left: '-14px',
    width: '30px',
    height: '30px',
    borderRadius: '4px',
    transform: 'rotate(45deg)',
    backgroundColor: theme.white,
    ...media(mediaQuery.mediaMaxLarge, {
      zIndex: 9,
      bottom: '-26px',
    }),
    ...media(mediaQuery.mediaMinLarge, {
      top: '0px',
    }),
  }),
});

const StockStatus = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: { bottom: theme.spacing(3) },
});

const StyledLoadingCircle = styled(LoadingCircle, {
  color: theme.white,
  height: theme.gamma,
  margin: { xy: 'auto' },
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const StyledCheckIcon = styled(CheckIcon, {
  height: '14px',
  width: '14px',
  marginLeft: '8px',
});

const ItemAddedText = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.white,
  height: theme.gamma,
  margin: { xy: 'auto' },
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const ButtonText = styled.span({
  transition: {
    property: 'all',
    duration: '0.2s',
    timingFunction: 'linear',
  },
  ...pseudo([':nth-child(n)[data-isloading="true"]'], {
    visibility: 'hidden',
    opacity: 0,
  }),
});

const StockStatusCircle = styled.div({
  borderRadius: '50%',
  width: '8px',
  height: '8px',
  backgroundColor: theme.notInStockRed,
  marginRight: theme.spacing(2),
  ...pseudo([':nth-child(n)[data-instock="true"]'], {
    backgroundColor: theme.inStockGreen,
  }),
});

const StockStatusText = styled.span({
  color: theme.black,
  font: { size: theme.alpha, weight: theme.fontWeight.bold },
  textTransform: 'uppercase',
});

const AddToCart = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const QuantitySelector = styled.div({
  backgroundColor: theme.primaryBackground,
  borderRadius: '100px',
  display: 'flex',
  height: '40px',
  alignItems: 'center',
  padding: { x: theme.spacing(3) },
});

const MinusButton = styled.button({
  ':disabled': {
    cursor: 'default',
    opacity: 0.6,
  },
});

const QuantityInput = styled.input({
  width: theme.spacing(7),
  backgroundColor: theme.primaryBackground,
  textAlign: 'center',
  MozAppearance: 'textfield', // hide number arrows Firefox
  ...pseudo(['::-webkit-outer-spin-button', '::-webkit-inner-spin-button'], {
    WebkitAppearance: 'none', // hide number arrows Chrome, Safari, Edge, Opera
    margin: { xy: 0 },
  }),
});

const StyledPlusIcon = styled(PlusToggleIcon, {
  height: '18px',
  width: '18px',
  fill: theme.black,
});

const StyledMinusIcon = styled(MinusToggleIcon, {
  height: '18px',
  width: '18px',
  fill: theme.black,
});

const AddToCartButton = styled(CtaButton, {
  position: 'relative',
  alignItems: 'center',
  width: '100%',
  fontSize: theme.tau,
  padding: { xy: '10px' },
  marginLeft: theme.spacing(2),
  minHeight: '40px',
  ...media(mediaQuery.mediaMinLarge, {
    padding: { xy: theme.spacing(3) },
  }),
});

const MoreInfoButton = styled(CtaButton, {
  width: '100%',
  textAlign: 'center',
  fontSize: theme.alpha,
  marginTop: theme.spacing(3),
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.beta,
  }),
});

const ProductCardWrapper = styled.div({
  ...media(mediaQuery.mediaMinLarge, {
    display: 'flex',
  }),
});

const ProductCardModalContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: { xy: theme.spacing(3) },
  backgroundColor: theme.white,
  wordBreak: 'break-word',
  top: theme.spacing(4),
  zIndex: 9,
  boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.2)',
  borderRadius: '10px',
  ...media(mediaQuery.mediaMaxLarge, {
    maxWidth: '345px',
  }),
  ...media(mediaQuery.mediaMinLarge, {
    padding: { xy: theme.spacing(4) },
    paddingRight: theme.spacing(3),
    maxWidth: '460px',
  }),
});

const PopperWrapper = styled.div({
  display: 'none',
  zIndex: 9,
  ...pseudo([':nth-child(n)[data-ishovered="true"]'], {
    display: 'inline-block',
    ...media(mediaQuery.mediaMinLarge, {
      display: 'inline-block',
    }),
  }),
  ...pseudo([':nth-child(n)[data-popper-placement="right"] > #arrow'], {
    left: '-12px',
  }),
  ...pseudo([':nth-child(n)[data-popper-placement="left"] > #arrow'], {
    right: '18px',
  }),
  ...pseudo([':nth-child(n)[data-popper-placement="top"] > #arrow'], {
    bottom: '12px',
    zIndex: 9,
    ...media(mediaQuery.mediaMinLarge, {
      bottom: '18px',
    }),
  }),
  ...pseudo([':nth-child(n)[data-popper-placement="bottom"] > #arrow'], {
    top: '-9px',
    zIndex: 9,
    ...media(mediaQuery.mediaMinLarge, {
      top: '-14px',
    }),
  }),
});

const AttributeArea = styled.div({
  paddingLeft: theme.spacing(3),
  paddingTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  zIndex: 10,
  ...media(mediaQuery.mediaMinLarge, {
    paddingLeft: theme.spacing(3),
  }),
});

const CloseIconWrapper = styled.div({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'end',
  marginRight: '10px',
  marginBottom: '10px',
});

const StyledCloseIcon = styled(CloseIcon, {
  height: theme.spacing(4),
});

const TextWrapper = styled.div({
  paddingLeft: theme.spacing(3),
  marginBottom: theme.spacing(5),
});

const ProductTitle = styled.h3({
  font: { size: theme.delta, weight: theme.fontWeight.bold },
  color: theme.black,
  lineHeight: '21.6px',
  marginBottom: theme.spacing(2),
  ...media(mediaQuery.mediaMinLarge, {
    marginBottom: theme.spacing(3),
  }),
});

const QuantityText = styled.span({
  font: { size: theme.beta, weight: theme.fontWeight.bold },
  color: theme.middleGray,
  textTransform: 'uppercase',
});

const ProductCard = styled.div({
  position: 'relative',
  justifyContent: 'center',
  display: 'flex',
  width: '100%',
  height: '97px',
  backgroundColor: theme.primaryBackground,
  paddingTop: '10px',
  paddingBottom: '14px',
  cursor: 'pointer',
  ...pseudo([':nth-child(n)[data-is-active="true"]'], {
    backgroundColor: theme.middleGray,
    cursor: 'unset',
  }),
});

const ProductImage = styled.img({
  height: '100%',
  width: '100%',
  maxWidth: '60px',
  objectFit: 'contain',
});

const ModalProductImage = styled.img({
  height: '100%',
  maxHeight: '180px',
  maxWidth: '125px',
  objectFit: 'contain',
  zIndex: 10,
  ...media(mediaQuery.mediaMinLarge, {
    maxWidth: '215px',
    maxHeight: '312px',
  }),
});

export default PlanogramProductCard;
