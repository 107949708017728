import React from 'react';
import { styled, theme } from '../Theme';
import ImageModel from '../Assets/Models/ImageModel.interface';
import { media } from '@glitz/core';

type ProductImages = {
  image: ImageModel;
};

function ProductImagesContainer({ image }: ProductImages) {
  return (
    <MainImageDiv>
      <MainImage src={image?.src} alt={image?.alt} />
    </MainImageDiv>
  );
}

const MainImageDiv = styled.div({
  position: 'relative',
  display: 'flex',
  padding: {
    xy: theme.gamma,
  },
  paddingBottom: 0,
  filter: theme.shadows.productImageShadow,
  margin: { x: `-${theme.gamma}` },
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { x: theme.none },
  }),
});

const MainImage = styled.img({
  width: '100%',
  maxHeight: theme.productImageMaxHeight,
  objectFit: 'contain',
  ...media(theme.mediaQuery.mediaMinMedium, {
    maxHeight: theme.productImageMaxHeight,
  }),
});

export default ProductImagesContainer;
