import React from 'react';
import { styled } from '@glitz/react';
import { StyleOrStyleArray } from '@glitz/type';
import ProductInGrid from './Models/ProductInGrid.interface';
import PlanogramProductCard from './PlanogramProductCard';
import { theme } from '../Theme';
import { media, pseudo } from '@glitz/core';
import { mediaQuery } from '../Theme/mediaQueries';

type PropType = {
  row: ProductInGrid[];
  isLast: boolean;
  useScroll: boolean;
  planogramId: string;
};

function PlanogramRowGrid({ row, isLast, useScroll, planogramId }: PropType) {
  const GridStyle: StyleOrStyleArray = {
    gridTemplateColumns: `repeat(${row.length}, minmax(0, 1fr))`,
    marginBottom: !isLast ? '10px' : 0,
  };

  return (
    <Grid css={GridStyle} data-usescroll={useScroll}>
      {row.map((product: ProductInGrid) => (
        <PlanogramProductCard
          key={product.bookingNumber}
          product={product}
          planogramId={planogramId}
        />
      ))}
    </Grid>
  );
}

const Grid = styled.div({
  display: 'grid',
  gridColumnGap: theme.spacing(3),
  ...media(mediaQuery.mediaMinMedium, {
    width: '730px',
  }),
  ...media(mediaQuery.mediaMaxLarge, {
    ...pseudo([':nth-child(n)[data-usescroll="true"]'], {
      width: '730px',
    }),
  }),
  ...media(mediaQuery.mediaMinLarge, {
    width: '100%',
  }),
});

export default PlanogramRowGrid;
