import React from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import PlanogramPageModel from './Models/PlanogramPageModel.interface';
import { styled } from '@glitz/react';
import { theme } from '../Theme';
import { media } from '@glitz/core';
import KexLink from '../Shared/KexLink/KexLink';
import ArrowLeftIconPrimary from '../Shared/Icons/ArrowLeftIconPrimary';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import ProductImagesContainer from './ProductImagesContainer';
import AttributeArea from './AttributeArea';

function PlanogramProductPage() {
  const {
    translations: { 'productPage/backToPlanogram': backToPlanogramLabel },
    placeholderImages: { planogramPlaceholder: planogramPlaceholderImg },
  } = useAppSettingsData();

  const {
    code,
    planogramUrl,
    variationCollection,
    planogramVariantList,
    shortDescription,
  } = useCurrentPage<PlanogramPageModel>();

  const planogram = variationCollection && variationCollection[0];

  return (
    <div>
      <BackLinkWrapper>
        <ArrowLeftIcon />
        <BackLink href={planogramUrl}>{backToPlanogramLabel}</BackLink>
      </BackLinkWrapper>
      <Section>
        <Top>
          {planogram.image ? (
            <ProductImage>
              <ProductImagesContainer key={code} image={planogram.image} />
            </ProductImage>
          ) : (
            <ProductImage>
              <ProductImagesContainer image={planogramPlaceholderImg} />
            </ProductImage>
          )}
          <AttributeArea
            key={code}
            bookingNumber={''}
            planogramInputQuantity={0}
            variation={planogram}
            planogramVariantList={planogramVariantList}
            shortDescription={shortDescription}
          />
        </Top>
      </Section>
    </div>
  );
}

const BackLinkWrapper = styled.div({
  position: 'absolute',
  top: theme.desktopHeaderHeight,
  left: 0,
  right: 0,
  margin: { x: 'auto' },
  maxWidth: theme.screenMaxWidth,
  width: '100%',
  paddingTop: '18px',
  alignItems: 'center',
  display: 'flex',
  paddingLeft: '32px',
  lineHeight: 1.33,
  letterSpacing: 0.45,
});

const ArrowLeftIcon = styled(ArrowLeftIconPrimary, {
  fill: theme.linkColor,
  marginRight: theme.spacing(4),
});

const BackLink = styled(KexLink, {
  fontSize: theme.gamma,
  color: theme.linkColor,
  cursor: 'pointer',
  textDecoration: 'underline',
});

const Section = styled.section({
  backgroundColor: theme.primaryBackground,
  maxWidth: '100%',
  position: 'relative',
  margin: { top: theme.spacing(16), bottom: theme.spacing(16) },
  padding: { x: theme.large },
  ...media(theme.mediaQuery.mediaMinSmall, {
    padding: { x: theme.great },
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { top: theme.spacing(13), bottom: theme.spacing(32) },
  }),
});

const Top = styled.div({
  width: '100%',
  ...media(theme.mediaQuery.mediaMinHuge, {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.massive,
  }),
});

const ProductImage = styled.div({
  maxWidth: '100%',
  ...media(theme.mediaQuery.mediaMinMedium, {
    gridColumn: {
      start: 1,
      end: 3,
    },
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridColumn: {
      start: 1,
      end: 1,
    },
  }),
});

const ModalProductImage = styled.img({
  height: '100%',
  width: '100%',
  objectFit: 'contain',
});

export default PlanogramProductPage;
